.innerBox {
  box-shadow: 0.5rem rgba(0, 0, 0, 0.1);
  outline: 0.1rem solid gray;

  outline-offset: -0.1rem;
  cursor: pointer;
}
.innerBox:hover {
  outline: 0.2rem solid rgba(63, 153, 51, 0.562);
  outline-offset: 0;
}


.price__text {
  font-size: 20px;
  margin-top: 55px;
  text-align: center;
  font-family: 'Gruppo', cursive;
  font-weight: bold;
}

.productCol {
  margin-bottom: 35px;
}

.product_title {
  background: yellowgreen;
  padding: 12px;
  color: white;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
  font-weight: bolder;
}

@media (max-width: 400px) {
  .innerBox {
    width: 150%;
  }
  }
