.box {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 5fr 4fr;
  grid-template-areas: "form info" "form map";
  grid-gap: 0px;
  height: 200px;
}
.contact {
  padding: 10px;
  background-color: white;
  box-shadow: 0 0px 10px black;
  height: 420px;
  /* background: linear-gradient(rgba(41, 241, 6, 0.502), rgba(255, 255, 255, 0.7)) ; */
}

.info .infoBox div {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  /* justify-content: center; */
  margin-left: 220px;
  /* margin: auto; */
}

.info .infoBox div span {
  width: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-size: 20px;
  color: black;
  /* border-radius: 50%; */
  padding: 20px;
  text-align: center;
}

.info .infoBox div p {
  font-size: 140%;
  font-weight: lighter;
  margin-top: 5px;
  color: black;
  text-align: center;
}

.info .infoBox div a {
  color: black;
  text-decoration: none;
  font-size: 120%;
  font-weight: lighter;
  text-align: center;
}
.info h3 {
  padding: 5px;
  text-decoration: underline;
  font-weight: lighter;
  font-size: 220%;
  color: black;
  text-align: center;
}
.map {
  padding: 0px;
}
.map iframe {
  width: 100%;
  height: 100%;
}

.iconPar {
  font-size: 30px;
  text-align: center;
  font-weight: lighter;
  margin-top: 15px;
  text-decoration: underline;
}

.icon {
  display: flex;
  text-align: center;
}

.icon {
  margin: auto;
  align-items: center;
  justify-content: center;
}
.icon a :hover {
  color: green;
  font-size: 40px;
  width: 40px;
  height: 40px;
}

.first_icon {
  font-size: 30px;
  align-items: center;
  justify-content: center;
  color: rgb(255, 0, 179);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
  margin-right: 20px;
}
.second_icon {
  font-size: 30px;
  align-items: center;
  justify-content: center;
  color: blue;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
  margin-right: 20px;
}
.third_icon {
  font-size: 30px;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  color: red;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
}
.fourth_icon {
  font-size: 30px;
  align-items: center;
  justify-content: center;
  color: rgb(0, 81, 255);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 15px;
  margin-right: 20px;
}

.infobox2 {
  height: 42%;
  width: 100%;
}
@media (max-width: 991px) {
  .box {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: "info" "map";
  }
  .map {
    height: 300px;
  }
  .info h3 {
    margin-left: 60px;
  }
  .info .infoBox div {
    margin-left: 80px;
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .box {
    width: 152%;
  }
}
