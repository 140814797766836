.shop {
  width: 100%;
  background: url("/public/images/Crop.jpg");
  height: 450px;
  background-attachment: fixed;
}
.container_stillimage {
  width: 100%;
  margin: 0 auto;
}

.shop__text {
  height: 450px;
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: center;
  margin: 0 auto;
  color: #000000;
}
.shop__text h1 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.5px;
  font-family: "Gruppo", cursive;
}
.shop__btn {
  margin-top: 35px;
}

@media (max-width: 400px) {
  .shop__text h1 {
    font-size: 40px;
    margin-left: -10px;
  }
  .shop{
    width: 152%;
  }
}
