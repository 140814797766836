.career_bg {
  width: 100%;
  background: url("/public/images/Bg1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 50px;
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(174, 198, 235, 0.708), rgba(27, 75, 5, 0.7));
}

.mainform {
  width: 100%;
  max-width: 500px;
  border: 2px solid rgba(2, 63, 2, 0.669);
  background-color: #66a80f37;
  /* height: 100%; */
  max-height: 1000px;
  padding: 25px;
  align-items: center;
  justify-content: center;
  margin: auto;
  
}

.mainheading {
  text-align: center;
}
.mainheading h1 {
  font-size: 250%;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 50px;
}
.mainheading h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 250%;
}

.mainheading p {
  font-size: 25px;
  margin-bottom: 40px;
  padding: 5px;
  font-weight: lighter;
}
.mainbox {
  margin-top: -50px;
  margin-bottom: 80px;
}

.mainheading h1::after {
  content: "";
  background: #66a80f;
  display: block;
  height: 3px;
  width: 170px;
  margin: 20px auto 5px;
}

.btn {
  display: block;
  margin: auto;
  width: 100%;
  max-width: 100px;
  color: black;
  background-color: white;
  margin-top: 20px;
}


@media (max-width: 400px) {
  .career_bg{
    width: 160%;
  }
  .mainheading{
    width: 160%;
  }
  }

