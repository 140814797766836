.banner {
  width: 100%;
  height: 115vh;
  background: url("/public/images/Bg2.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 80px;
  padding: 80px;
  z-index: 2;
}

.banner__text h1 {
  font-size: 70px;
  line-height: 80px;
  font-weight: lighter;
  margin-top: 100px;
  padding-top: 80px;
  font-family: "Syncopate", sans-serif;
  /* font-family: 'Sanchez', serif; */
  /* font-family: "The Nautigal", cursive; */
  color: rgba(0, 0, 0, 0.669);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.banner__btn {
  margin-top: 35px;
}

#quote {
  /* background-color: rgba(0, 0, 0, 0.322); */
  width: fit-content;
  color: rgb(0, 0, 0);
  font-weight: bolder;
  margin-top: 30px;
  font-size: 22px;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
  margin-left: 450px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.me-auto1 {
  color: #000000da;
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
  display: inline-block;
  position: relative;
  padding-bottom: 0.25rem;
  padding-right: 300px;
}

.me-auto {
  color: #000000da;
  text-transform: uppercase;
  font-weight: 300;
  cursor: pointer;
  font-size: 20px;
  padding: 30px;
  text-decoration: none;
  font-family: "Quicksand", sans-serif;
  display: inline-block;
  position: relative;
  padding-bottom: 0.25rem;
}

.me-auto::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: green;
  /* color: green;
  text-decoration: underline; */
}

.me-auto:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}
.logo {
  margin-left: -10px;
  width: 200px;
}
/* .navbar {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
  background-color: rgba(255, 255, 255, 0.447);
  width: 100%;
  padding: 200px;
  text-decoration: none;
} */

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(46, 87, 148, 0.708), rgba(28, 86, 1, 0.7));
  z-index: -1;
}

@media (max-width: 400px) {
  .banner__text h1 {
    font-size: 50px;
  }
  .banner {
    width: 152%;
  }
}
