
.sectionTitle {
  color: #000000;
  font-weight: 500;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 250%;
  margin-bottom: 20px;
  padding-top: 20px;
}

.sectionTitle::after {
  content: "";
  background: #66a80f;
  display: block;
  height: 3px;
  width: 170px;
  margin: 20px auto 5px;
}

.h1{
  font-size: 40px;
  color: #000000;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 15px;
  background-color: #6dff0449;
  width: max-content;
  font-family: 'Gruppo', cursive;
}

.aboutDesc {
  text-align: justify;
  line-height: 30px;
  font-size: 20px;
    color: #020202;
    font-weight: lighter;
    font-family: 'Quicksand', sans-serif;
}

.aboutImage img{
 width: 90%;
 height: 90%;
 border-radius: 50%;
 margin-left: 30px;
}

.readmore {
  background: #66a80f;
  text-decoration: none;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  border-radius: 30px;
  font-size: 15px;
  padding: 20px 40px;
  cursor: pointer;
  font-family: 'Gruppo', cursive;
}

.read-more-state{
  display: none;
}

.content{
  max-height: 0;
  opacity: 0;
  font-size: 0;
  transition: 0.45s ease;
}
.content b{
  font-weight: bold;
}

.read-more-state:checked~.aboutDesc .content{
  opacity: 1;
  font-size: inherit;
}

.read-more-state:checked ~ label{
  visibility: hidden;
}

@media (max-width: 400px) {
.aboutDesc{
  width: 150%;
margin-bottom: -10px;
}
.aboutImage{
margin-left: 70px;

}
.sectionTitle{
 width: 153%;
 font-family: 'Montserrat', sans-serif;
}
  }