.App {
  margin-top: -80px;
  margin-bottom: 50px;
}



.gallery  {
  height: 300px;
  width: 400px;
  margin: 20px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.gallery a img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.gallery a img:hover {
  transform: scale(1.4);
}

.modalImage{
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
}

.modalImage img{
  width: 100%;
  height: 100%;
}

@media (max-width: 400px) {
  .modalImage{
    width: 160%;
  }
  }

