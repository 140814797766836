.profile {
  padding: 10px 10px 10px;
  border: 5px solid white;
  background-color: #80c30d70;
  text-align: center;
}
.profile img {
  border: 10px solid white;
  min-width: 100%;
  height: 250px;
}
.user {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.profile h3 {
  font-size: 20px;
  margin-top: 15px;
  text-align: center;
  color: rgb(202, 8, 8);
}
span {
  font-size: 12px;
  color: black;
}
blockquote {
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}
blockquote::before {
  content: "\201C";
  font-size: 50px;
  position: relative;
  color: red;
  line-height: 20px;
  bottom: -15px;
  right: 5px;
}
blockquote::after {
  content: "\201D";
  font-size: 50px;
  position: relative;
  color: rgb(228, 0, 0);
  line-height: 10px;
  bottom: -15px;
  left: 5px;
}

.profile:hover {
  box-shadow: 0 0 15px 5px rgba(3, 125, 7, 0.532);
  cursor: pointer;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 250%;
  text-align: center;
  margin-bottom: 20px;
}
